import apartments from "./apartments";

const entries = {
    fields: {
        media: ["cms.media"],
        tags: ["cms.tags"],
        property: "cms.properties",
    },
};

export default {
    "cms.media": {
        fields: {},
    },
    "cms.items": {
        fields: {
            media: ["cms.media"],
        },
        options: {
            processStrategy: "@parent",
        },
    },
    "cms.enum-values": {
        fields: {},
    },
    "cms.enum-definitions": {
        fields: {
            values: ["cms.enum-values"],
        },
    },
    "cms.tags": {
        fields: {},
    },
    "cms.links": {
        fields: {
            children: ["cms.links"],
        },
        options: {
            processStrategy: "@parent",
        },
    },
    "cms.nav-links": {
        fields: {
            children: ["cms.nav-links"],
        },
        options: {
            processStrategy: "@parent",
        },
    },
    "cms.navigation": {
        fields: {
            links: ["cms.nav-links"],
        },
    },
    "cms.consents": {
        fields: {},
        options: {
            processStrategy: "@parent",
        },
    },
    "cms.components": {
        fields: {
            components: ["@self"],
            media: ["cms.media"],
            items: ["cms.items"],
            consents: ["cms.consents"],
            //link_ref: "cms.links",
            links: ["cms.links"],
        },
        refs: {
            navigation: "cms.navigation",
            tags: ["cms.tags"],
            properties: ["cms.properties"],
        },
        options: {
            processStrategy: "@parent",
        },
        override: ["attributes"],
    },
    "cms.sections": {
        fields: {
            items: ["cms.components"],
            gallery: ["cms.items"],
            media: ["cms.media"],
            tags: ["cms.tags"],
            links: ["cms.links"],
        },
        refs: {
            template: "@self",
        },
        override: ["attributes"],
    },
    "cms.sections.legacy": {
        fields: {
            items: ["cms.items"],
            media: ["cms.media"],
        },
    },
    "cms.pages": {
        fields: {
            media: ["cms.media"],
            open_graph: {
                media: ["cms.media"],
            },
        },
        refs: {
            sections: ["cms.sections"],
            layout: "cms.layouts",
            tags: ["cms.tags"],
            property: "cms.properties",
        },
    },
    "cms.layouts": {
        refs: {
            layout: "@self",
            sections: ["cms.sections"],
        },
    },
    "cms.entries.rooms": {
        ...entries,
        refs: {
            amenities: ["cms.enum-values"],
        },
    },
    "cms.entries.offers": {
        ...entries,
        refs: {
            ...entries.refs,
            sections: ["cms.sections"],
            layout: "cms.layouts",
        },
    },
    "cms.entries.projects": {
        ...entries,
        refs: {
            ...entries.refs,
            sections: ["cms.sections"],
            layout: "cms.layouts",
        },
        fields: {
            ...entries.fields,
            logo: ["cms.media"],
        },
    },
    "cms.entries.contacts": {
        ...entries,
    },
    "cms.entries.reviews": {
        ...entries,
    },
    "cms.entries.gallery": {
        ...entries,
    },
    "cms.entries.attractions": {
        ...entries,
    },
    "cms.entries.posts": {
        ...entries,
        refs: {
            ...entries.refs,
            sections: ["cms.sections"],
            layout: "cms.layouts",
        },
    },
    "cms.entries.rooms_conf": {
        ...entries,
        refs: {
            amenities: ["cms.enum-values"],
        },
    },
    "cms.products": {
        fields: {
            tags: ["cms.tags"],
            media: ["cms.media"],
        },
    },
    "cms.categories": {
        fields: {
            tags: ["cms.tags"],
            list_tags: ["cms.tags"],
        },
    },
    "cms.layout-ref": {
        refs: {
            layout: "cms.layouts",
        },
    },
    "cms.settings": {
        fields: {
            media: ["cms.media"],
            logo: "cms.media",
            logo_avatar: "cms.media",
            default_layouts: ["cms.layout-ref"],
        },
    },
    "cms.plan-resources": {},
    "cms.plans": {
        fields: {
            media: ["cms.media"],
        },
        refs: {
            resources_collection: ["cms.plan-resources"],
        },
        override: ["layers"],
    },
    "cms.properties": {
        fields: {
            media: ["cms.media"],
            tags: ["cms.tags"],
        },
    },
    "cms.integrations": {
        refs: {
            properties: ["cms.properties"],
            rooms_tag: "cms.tags",
        },
    },
    "cms.forms": {
        refs: {
            integrations: ["cms.integrations"],
        },
    },
    ...apartments,
};
